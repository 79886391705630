//@ts-nocheck
import React, { Suspense, lazy, useState, useEffect, FC, ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './shared/serviceWorker';
import { asyncWithLDProvider, ProviderConfig, LDProvider } from 'launchdarkly-react-client-sdk';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import './styles.scss';
import 'shared_components/src/assets/fonts/fonts.css';
import 'prismjs/themes/prism-coy.css';

// Lazy loading App component for faster initial load
const App = lazy(() => import('./shared/App'));

import {
	APP_URL_PREFIX,
	LD_CLIENT_ID,
	LD_CLIENT_EMAIL,
	LD_CLIENT_FIRST_NAME,
	LD_CLIENT_LAST_NAME,
} from 'shared_components/src/common/constants';

// Enhanced loading component with MUI Spinner
const LoadingComponent: FC = () => (
	<Box
		sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100vh',
		}}
	>
		<CircularProgress size={60} thickness={4} />
	</Box>
);

//
// ---------------- MAIN APPLICATION CODE ----------------
//

// Application root component with better LaunchDarkly error handling
const AppRoot: FC<{ tenant: string }> = ({ tenant }) => {
	// AppRoot contents will be rendered after LaunchDarkly is initialized
	return (
		<ErrorBoundary>
			<Suspense fallback={<LoadingComponent />}>
				<App />
			</Suspense>
		</ErrorBoundary>
	);
};

// Initialize application with error handling
const initApp = () => {
	const rootApp = document.getElementById('root');
	if (!rootApp) {
		console.error('Root element not found');
		return;
	}

	try {
		// Process URL path
		const path = getProcessedPath();

		if (path) {
			// history.replace(path + history.location.search);
			window.history.replaceState({}, '', window.location.origin + path + window.location.search);
		}

		const tenant = getTenantFromHostname();

		// Create root outside of try/catch to ensure proper cleanup on error
		const root = createRoot(rootApp);

		// Start with loading indicator
		root.render(<LoadingComponent />);

		// Initialize LaunchDarkly and then render app
		(async () => {
			try {
				// Use a Promise with timeout to avoid hanging
				const ldProviderPromise = asyncWithLDProvider({
					clientSideID: LD_CLIENT_ID,
					context: {
						kind: 'user',
						key: tenant,
						firstName: tenant,
						lastName: '',
						email: '',
					},
					reactOptions: {
						useCamelCaseFlagKeys: false,
					},
					// Add reasonable timeout to prevent hanging
					timeout: 5000,
				} as ProviderConfig);

				// Add our own timeout as a safety measure
				const timeoutPromise = new Promise((_, reject) => {
					setTimeout(() => reject(new Error('LaunchDarkly initialization timed out')), 6000);
				});

				// Race the promises to ensure we don't hang
				const LDProvider = await Promise.race([ldProviderPromise, timeoutPromise]).catch((error) => {
					console.error('LaunchDarkly initialization failed:', error);
					return null;
				});

				// Render with or without LaunchDarkly
				if (LDProvider) {
					root.render(
						<LDProvider>
							<AppRoot tenant={tenant} />
						</LDProvider>
					);
				} else {
					// Fallback render without LaunchDarkly
					root.render(<AppRoot tenant={tenant} />);
				}
			} catch (error) {
				console.error('LaunchDarkly initialization error:', error);
				// Fallback render without LaunchDarkly on error
				root.render(<AppRoot tenant={tenant} />);
			}
		})();
	} catch (error) {
		console.error('Application initialization failed:', error);
		// Display basic error UI if everything fails
		if (rootApp) {
			rootApp.innerHTML =
				'<div class="critical-error">Failed to initialize application. Please refresh or contact support.</div>';
		}
	}
};

// Start application initialization
initApp();

//
// ---------------- UTILITY COMPONENTS AND FUNCTIONS ----------------
//

// NOTE: Error Boundaries require class components as React doesn't yet provide a hooks-based API for this feature
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		console.error('Application error:', error, errorInfo);
		// Here you could log to an error reporting service
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className='error-container'>
					<h2>Something went wrong.</h2>
					<p>Please refresh the page or contact support if the issue persists.</p>
				</div>
			);
		}
		return this.props.children;
	}
}

/**
 * Extracts tenant information from the hostname
 * @returns {string} The tenant identifier
 */
function getTenantFromHostname(): string {
	const baseUrl = window.location.hostname;

	// Early return pattern for readability
	if (baseUrl === 'localhost') {
		return 'client';
	}

	if (baseUrl.includes('truuth.id')) {
		const splittedUrl = baseUrl.split('.');
		return splittedUrl.length > 0 ? splittedUrl[0] : '';
	}

	return '';
}

/**
 * Processes the URL path with APP_URL_PREFIX handling
 * @returns {string} The processed path
 */
function getProcessedPath(): string {
	const reg = new RegExp(APP_URL_PREFIX + '(/.*)$');
	const hasPrefix = location.pathname.search(APP_URL_PREFIX);
	const removedPath = (reg.exec(location.pathname) || [])[1];
	let path = APP_URL_PREFIX;

	if (removedPath !== undefined) {
		path += removedPath;
	} else {
		if (hasPrefix !== -1) {
			path = location.pathname;
		} else {
			path += location.pathname;
		}
	}

	return path;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
